import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Card, Typography } from "@mui/material";

export const ConfirmOrder = () => {
    return (
        <>
            <Typography
                variant="h5"
                fontWeight={900}
                textAlign={"center"}
                color={"#2B3674"}
                my={2}
            >
                Order Confirmation
            </Typography>
            <Box minHeight={"100vh"} p={"1em"}>
                {/* Order Confirmed Card */}
                <Card
                    sx={{
                        maxWidth: "xl",
                        height: "80vh",
                        mx: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "2em",
                        gap: "1em",
                    }}
                >
                    <CheckCircleIcon
                        sx={{ color: "#4BB543", fontSize: "4rem" }}
                    />
                    <Typography variant="h4" fontWeight={600}>
                        Order Confirmed
                    </Typography>
                    <Box>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            color={"#4B5563"}
                            mb={1}
                        >
                            Thank you for your purchase. Your order has been
                            confirmed.
                        </Typography>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            color={"#4B5563"}
                        >
                            An email receipt will be sent to you shortly.
                        </Typography>
                    </Box>
                </Card>
            </Box>
        </>
    );
};
