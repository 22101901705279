import {
    createCustomOrder,
    createCustomOrderPaymentIntent,
    ICustomOrder,
    IDBCustomOrder,
    updateCustomOrder,
    updateCustomOrderPaymentIntent,
} from "@/api/orders";
import { IPricing } from "@/api/settings";
import { IUpdateOrder } from "@/api/store";
import { CustomOrderFormElements } from "@/components/dashboard/orders/process/CustomOrderFormElements.tsx";
import { getCustomInitialFormData } from "@/utils";
import { FormContainer } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export interface OrderFormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    pickup_streetAddress1: string;
    pickup_streetAddress2: string;
    pickup_city: string;
    pickup_state: string;
    pickup_zipCode: string;
    delivery_streetAddress1: string;
    delivery_streetAddress2: string;
    delivery_city: string;
    delivery_state: string;
    delivery_zipCode: string;
    itemSize: string;
    deliveryWindow: string;
    itemFloor: number;
    requiresAssembly: boolean;
    weightLimit: boolean;
    needsWrapped: boolean;
    elevatorAvailable: boolean;
    itemDescription: string;
    estimatedValue: string;
    itemQuantity: number;
    addReferral: string;
    dropoffInstructions: string;
}

interface IOrderFormProps {
    order?: IDBCustomOrder;
    pricing?: IPricing;
}

export const CustomOrderForm = ({ order, pricing }: IOrderFormProps) => {
    const navigate = useNavigate();

    const initialValues: OrderFormData = getCustomInitialFormData(order);

    const {
        mutate: updateOrderMutate,
        isPending: updateOrderPending,
        error: updateOrderError,
    } = useMutation({
        mutationFn: ({
            orderId,
            orderUpdate,
        }: {
            orderId: number;
            orderUpdate: IUpdateOrder;
        }) => updateCustomOrder(orderId, orderUpdate),
        onError: () =>
            enqueueSnackbar("Failed to update order", { variant: "error" }),
        onSuccess: (_, { orderId }) => {
            enqueueSnackbar("Order updated successfully", {
                variant: "success",
            });
            if (pricing) {
                updatePaymentIntentMutate(orderId);
            } else {
                navigate(-1);
            }
        },
    });

    const {
        mutate: createCustomOrderMutate,
        isPending: createCustomOrderPending,
    } = useMutation({
        mutationFn: (order: ICustomOrder) => createCustomOrder(order),
        onError: () =>
            enqueueSnackbar("Failed to create order", {
                variant: "error",
            }),
        onSuccess: (order: IDBCustomOrder) => {
            if (order.id) paymentIntentMutate(order.id);
        },
    });

    const {
        mutate: paymentIntentMutate,
        isPending: paymentIntentPending,
        error: paymentIntentError,
    } = useMutation({
        mutationFn: async (orderId: number) => {
            const paymentIntent = await createCustomOrderPaymentIntent(orderId);
            return paymentIntent.id;
        },
        onError: () =>
            enqueueSnackbar("Failed to create payment intent", {
                variant: "error",
            }),
        onSuccess: (_, orderId: number) => {
            navigate(`/orders/${orderId}/checkout`);
        },
    });

    const {
        mutate: updatePaymentIntentMutate,
        isPending: updatePaymentIntentPending,
        error: updatePaymentIntentError,
    } = useMutation({
        mutationFn: (orderId: number) =>
            updateCustomOrderPaymentIntent(orderId),
        onError: () => {
            enqueueSnackbar("Failed to update payment intent", {
                variant: "error",
            });
        },
        onSuccess: (_, orderId: number) => {
            navigate(`/orders/${orderId}/checkout`);
        },
    });

    // form submit handler - update order or create payment intent
    const onSubmit = (data: OrderFormData) => {
        // if order, update order
        if (order) {
            const updateOrderData: ICustomOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                pickup_address: {
                    address1: data.pickup_streetAddress1,
                    address2: data.pickup_streetAddress2,
                    city: data.pickup_city,
                    state: data.pickup_state,
                    zip_code: data.pickup_zipCode,
                },
                delivery_address: {
                    address1: data.delivery_streetAddress1,
                    address2: data.delivery_streetAddress2,
                    city: data.delivery_city,
                    state: data.delivery_state,
                    zip_code: data.delivery_zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                referral: data.addReferral,
                dropoff_instructions: data.dropoffInstructions,
            };

            updateOrderMutate({
                orderId: order.id,
                orderUpdate: updateOrderData,
            });
        }

        // if no order, create payment intent
        if (!order) {
            const orderData: ICustomOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                pickup_address: {
                    address1: data.pickup_streetAddress1,
                    address2: data.pickup_streetAddress2,
                    city: data.pickup_city,
                    state: data.pickup_state,
                    zip_code: data.pickup_zipCode,
                },
                delivery_address: {
                    address1: data.delivery_streetAddress1,
                    address2: data.delivery_streetAddress2,
                    city: data.delivery_city,
                    state: data.delivery_state,
                    zip_code: data.delivery_zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                referral: data.addReferral,
                dropoff_instructions: data.dropoffInstructions,
            };

            createCustomOrderMutate(orderData);
        }
    };

    return (
        <FormContainer defaultValues={initialValues} onSuccess={onSubmit}>
            <CustomOrderFormElements
                error={
                    updateOrderError ||
                    updatePaymentIntentError ||
                    paymentIntentError
                }
                order={order}
                pricing={pricing}
                loading={
                    createCustomOrderPending ||
                    paymentIntentPending ||
                    updateOrderPending ||
                    updatePaymentIntentPending
                }
            />
        </FormContainer>
    );
};
