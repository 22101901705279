import { DBStoreOrder } from "@/api/store";
import { Card, List, Stack, Typography } from "@mui/material";
import { useAsync, UseAsyncActions } from "@react-hookz/web";
import React, { useEffect } from "react";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import { addOrderImage, deleteOrderImage } from "@/api/orders";
import { enqueueSnackbar } from "notistack";
import { OrderImageDropZone } from "../OrderImageDropZone";
import { OrderImagePreview } from "../OrderImagePreview";

interface IOrderImagesCardProps {
    order: DBStoreOrder;
    getOrderActions: UseAsyncActions<DBStoreOrder, [order_id: string | number]>;
}

export const OrderImagesCard = ({
    order,
    getOrderActions,
}: IOrderImagesCardProps) => {
    const [hasImages, setHasImages] = React.useState<boolean>(
        (order.images && order.images.length > 0) || false
    );

    const [addImageState, addImageActions] = useAsync(addOrderImage);
    const [deleteImageState, deleteImageActions] = useAsync(deleteOrderImage);

    useEffect(() => {
        if (addImageState.status === "success") {
            getOrderActions.execute(order.id);

            setHasImages(true);
            enqueueSnackbar("Image uploaded successfully", {
                variant: "success",
            });
        }

        if (addImageState.status === "error") {
            enqueueSnackbar("Failed to upload image", { variant: "error" });
        }
    }, [addImageState.status, order.id, getOrderActions]);

    useEffect(() => {
        if (deleteImageState.status === "success") {
            getOrderActions.execute(order.id);

            enqueueSnackbar("Image successfully deleted", {
                variant: "success",
            });
        }

        if (deleteImageState.status === "error") {
            enqueueSnackbar("Failed to delete image", { variant: "error" });
        }
    }, [deleteImageState.status, order.id, getOrderActions]);

    return (
        <>
            <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
                <Stack direction={"row"} alignItems={"center"} mb={1}>
                    <BurstModeIcon sx={{ marginRight: 1 }} color="inherit" />
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Images
                    </Typography>
                </Stack>

                <OrderImageDropZone
                    orderId={order.id}
                    loading={addImageState.status === "loading"}
                    addImageActions={addImageActions}
                />
                {hasImages && (
                    <List disablePadding sx={{ mt: 2 }}>
                        {order.images?.map((image) => (
                            <OrderImagePreview
                                orderId={order.id}
                                image={image}
                                deleteImageActions={deleteImageActions}
                            />
                        ))}
                    </List>
                )}
            </Card>
        </>
    );
};
