import { getCustomOrder } from "@/api/orders";
import { getPricing } from "@/api/settings";
import { CustomOrderForm } from "@/components/dashboard/orders";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { Footer } from "./components/Footer";

export const CustomOrder = () => {
    const [getPricingState, getPricingActions] = useAsync(getPricing);
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get("order_id");

    const { data: customOrder, isFetching: customOrderFetching } = useQuery({
        queryKey: ["custom-order-shipping-form", orderId],
        queryFn: () => {
            if (orderId) {
                return getCustomOrder(parseInt(orderId));
            } else throw Error("No order id");
        },
        enabled: !!orderId,
    });

    // fetch store on mount
    useMountEffect(() => {
        getPricingActions.execute();
    });

    const pricing = getPricingState.result;

    if (getPricingState.status === "loading" || customOrderFetching)
        return (
            <Backdrop
                open={getPricingState.status === "loading"}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    return (
        <Box
            pt={"1em"}
            bgcolor={"#F3F4F6"}
            minHeight={"100vh"}
            width={"100%"}
            overflow={"auto"}
        >
            {/* Store Info */}
            <Typography
                variant="h5"
                textAlign={"center"}
                fontWeight={900}
                color={"#2B3674"}
            >
                Local Delivery
            </Typography>
            <Typography
                variant="body1"
                textAlign={"center"}
                fontWeight={400}
                color={"#2B3674"}
                mb={2}
            >
                Powered by Truk-Em
            </Typography>
            <Box
                minHeight={"100vh"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
            >
                {/* Render Order Form with store */}
                <Box p={"1em"} maxWidth={"xl"} mx={"auto"} mb={"1em"}>
                    <CustomOrderForm pricing={pricing} order={customOrder} />
                </Box>

                {/* Footer */}
                <Footer />
            </Box>
        </Box>
    );
};

export default CustomOrder;
