import { getOrder } from "@/api/orders";
import { getPricing } from "@/api/settings";
import { getStore } from "@/api/store";
import {
    OrderForm,
    OrderNotFound,
    StoreNotFound,
} from "@/components/dashboard";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { Footer } from "./components/Footer";

export const ShippingForm = () => {
    const { store_id } = useParams();
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get("order_id");

    const [getStoreState, getStoreActions] = useAsync(getStore);
    const [getPricingState, getPricingActions] = useAsync(getPricing);

    const {
        data: storeOrder,
        isFetching: storeOrderFetching,
        error: storeOrderError,
    } = useQuery({
        queryKey: ["store-order-shipping-form", orderId],
        queryFn: () => {
            if (orderId) {
                return getOrder(orderId);
            } else throw Error("No order id");
        },
        enabled: !!orderId,
    });

    // fetch store on mount
    useMountEffect(() => {
        getPricingActions.execute();
        if (store_id) getStoreActions.execute(store_id);
    });

    if (
        getStoreState.status === "loading" ||
        getPricingState.status === "loading" ||
        storeOrderFetching
    )
        return (
            <Backdrop
                open={getStoreState.status === "loading"}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (getStoreState.error) {
        const error = getStoreState.error as AxiosError;
        if (error.response && error.response.status === 404) {
            return <StoreNotFound />;
        }
        return (
            <Typography variant="h6">
                There was an error loading the store with the id #{store_id}.
            </Typography>
        );
    }

    if (storeOrderError) {
        return <OrderNotFound />;
    }

    if (!getStoreState.result || !getPricingState.result) return null;

    const store = getStoreState.result;
    const pricing = getPricingState.result;

    return (
        <Box
            pt={"1em"}
            bgcolor={"#F3F4F6"}
            minHeight={"100vh"}
            width={"100%"}
            overflow={"auto"}
        >
            {/* Store Info */}
            <Typography
                variant="h5"
                textAlign={"center"}
                fontWeight={900}
                color={"#2B3674"}
            >
                Local Delivery Information
            </Typography>
            <Typography
                variant="h6"
                textAlign={"center"}
                fontWeight={600}
                color={"#2B3674"}
                mb={1}
            >
                {store.name}
            </Typography>
            <Typography
                variant="body1"
                textAlign={"center"}
                textTransform={"capitalize"}
                color={"#2B3674"}
                mb={1}
            >
                {[
                    store.store_address.address1,
                    store.store_address.address2,
                    store.store_address.city,
                    store.store_address.state,
                    store.store_address.zip_code,
                ]
                    .filter(Boolean)
                    .join(", ")}
            </Typography>

            <Box
                minHeight={"100vh"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
            >
                {/* Render Order Form with store */}
                <Box p={"1em"} maxWidth={"xl"} mx={"auto"} mb={"1em"}>
                    <OrderForm
                        order={storeOrder}
                        store={store}
                        pricing={pricing}
                    />
                </Box>

                {/* Footer */}
                <Footer />
            </Box>
        </Box>
    );
};

export default ShippingForm;
