import { Box, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "@/api/auth";
import {
    FormBox,
    FormScreenContainer,
} from "@/components/dashboard/forms/FormContainers";
import Link from "@/components/ui/Link";
import {
    FormContainer,
    FormPasswordElement,
    FormButton as Button,
} from "@rhf-kit/mui";

interface IResetPasswordFormData {
    password: string;
    confirmPassword: string;
}

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [resetPasswordState, resetPasswordActions] = useAsync(resetPassword);

    const onSubmit = (data: IResetPasswordFormData) => {
        const token = searchParams.get("token");

        if (!token) {
            return;
        }

        resetPasswordActions.execute({
            token: token,
            new_password: data.password,
        });
    };

    useEffect(() => {
        if (resetPasswordState.status === "success") {
            enqueueSnackbar("Password reset successfully. Please sign in.", {
                variant: "success",
            });
            navigate("/login");
        }
        if (resetPasswordState.status === "error") {
            enqueueSnackbar("Failed to reset password", { variant: "error" });
        }
    }, [resetPasswordState, navigate]);

    const initialData: IResetPasswordFormData = {
        password: "",
        confirmPassword: "",
    };

    useEffect(() => {
        if (!searchParams.has("token")) {
            navigate("/");
        }
    }, [searchParams, navigate]);

    return (
        <FormScreenContainer>
            <FormBox>
                <Typography
                    variant="h5"
                    fontWeight={600}
                    textAlign={"center"}
                    mb={2}
                >
                    Reset Password
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Enter and confirm your new password below to reset it.
                </Typography>

                <FormContainer defaultValues={initialData} onSuccess={onSubmit}>
                    <FormPasswordElement
                        name={"password"}
                        label={"New Password"}
                        required
                        margin={"normal"}
                        fullWidth
                    />
                    <FormPasswordElement
                        name={"confirmPassword"}
                        label={"Confirm Password"}
                        required
                        margin={"normal"}
                        fullWidth
                        rules={{
                            validate: (value, fieldValues) =>
                                value === fieldValues.password ||
                                "The passwords do not match",
                        }}
                    />

                    <Button
                        sx={{
                            background: "#40ABBA",
                            ":hover": {
                                background: "#3a9aa7",
                            },
                            fontWeight: "bold",
                        }}
                        variant="contained"
                        fullWidth
                        loading={resetPasswordState.status === "loading"}
                        loadingPosition={"end"}
                    >
                        Reset Password
                    </Button>
                </FormContainer>
                <Typography variant="body1">
                    Remember your password? <Link to="/login">Sign in</Link>
                </Typography>
            </FormBox>
            <Box color="GrayText">
                <Typography variant="body1">
                    © 2024 Pollytech LLC - Terms of Use
                </Typography>
            </Box>
        </FormScreenContainer>
    );
};

export default ResetPassword;
