import {
    getCustomOrder,
    getPaymentIntent,
    ICustomOrderPaymentIntent,
    IDBCustomOrder,
} from "@/api/orders.ts";
import { PaymentIntentNotFound } from "@/components/dashboard";
import { BackButton } from "@/components/ui/BackButton.tsx";
import { CheckoutForm } from "@/pages/order/components/CheckoutForm.tsx";
import {
    Backdrop,
    Box,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const CustomOrderCheckout = () => {
    const { order_id } = useParams();
    const navigate = useNavigate();

    const { isPending: orderIsPending, data: order } = useQuery<IDBCustomOrder>(
        {
            queryKey: ["checkout-store-order", order_id],
            queryFn: () => {
                if (order_id) {
                    return getCustomOrder(parseInt(order_id));
                } else throw Error("No payment intent id");
            },
        }
    );

    const {
        isPending: paymentIntentIsPending,
        isError: paymentIntentIsError,
        data: paymentIntent,
    } = useQuery<ICustomOrderPaymentIntent>({
        queryKey: ["checkout-payment-intent", order],
        queryFn: () => {
            if (order && order.payment_intent_id) {
                return getPaymentIntent(order.payment_intent_id);
            } else throw Error("No payment intent id");
        },
    });

    if (paymentIntentIsPending || orderIsPending)
        return (
            <Backdrop
                open={paymentIntentIsPending || orderIsPending}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (paymentIntentIsError) return <PaymentIntentNotFound />;

    if (!paymentIntent || !order) return null;

    const pickupAddress = [
        order.pickup_address.address1,
        order.pickup_address.address2,
        order.pickup_address.city,
        order.pickup_address.state,
        order.pickup_address.zip_code,
    ]
        .filter(Boolean)
        .join(", ");
    const deliveryAddress = [
        order.delivery_address.address1,
        order.delivery_address.address2,
        order.delivery_address.city,
        order.delivery_address.state,
        order.delivery_address.zip_code,
    ]
        .filter(Boolean)
        .join(", ");

    if (paymentIntent.status === "succeeded" || order.payment_intent_paid) {
        return <Navigate to={`/orders/create`} replace />;
    }
    return (
        <Box
            bgcolor={"#F3F4F6"}
            minHeight={"100vh"}
            width={"100%"}
            overflow={"auto"}
        >
            <Elements
                options={{ clientSecret: paymentIntent.client_secret }}
                stripe={stripePromise}
            >
                <Box p={"1em"}>
                    {/* Payment Information Header */}
                    <Typography
                        variant="h5"
                        textAlign={"center"}
                        fontWeight={900}
                        color={"#2B3674"}
                    >
                        Order Checkout
                    </Typography>
                    <Box maxWidth={"lg"} mx={"auto"} my={"1em"}>
                        {/* Back button sends back payment intent state */}
                        <BackButton
                            onClick={() =>
                                navigate(`/orders/create?order_id=${order.id}`)
                            }
                        />
                        <Box
                            display={"flex"}
                            flexDirection={{ xs: "column", md: "row" }}
                            gap={4}
                            mb={2}
                        >
                            {/* Pickup address */}
                            <TextField
                                fullWidth
                                size="small"
                                label="Pickup Address"
                                disabled
                                variant="outlined"
                                value={pickupAddress}
                            />
                            {/* Delivery address */}
                            <TextField
                                fullWidth
                                size="small"
                                label="Delivery Address"
                                disabled
                                variant="outlined"
                                value={deliveryAddress}
                            />
                        </Box>
                        {/* Render Checkout Form */}
                        <CheckoutForm
                            order={order}
                            paymentIntent={paymentIntent}
                        />
                    </Box>
                </Box>
            </Elements>
        </Box>
    );
};

export default CustomOrderCheckout;
