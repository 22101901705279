import AssignmentIcon from "@mui/icons-material/Assignment";
import { Typography } from "@mui/material";
import { ConditionalContainer } from "./ConditionalContainer";

export const OrderNotFound = () => {
    return (
        <ConditionalContainer>
            <AssignmentIcon sx={{ fontSize: 128, color: "#40ABBA", mb: 2 }} />
            <Typography variant="h5" fontWeight={600}>
                Order Not Found
            </Typography>
        </ConditionalContainer>
    );
};
