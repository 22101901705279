import { IOrderImage } from "@/api/store";
import { Close } from "@mui/icons-material";
import {
    Tooltip,
    ListItem,
    IconButton,
    alpha,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { UseAsyncActions } from "@react-hookz/web";
import { FormButton } from "@rhf-kit/mui";
import React from "react";

const BUNNY_PULL_ZONE = import.meta.env.VITE_BUNNY_PULL_ZONE;

interface OrderImagePreviewProps {
    orderId: string | number;
    image: IOrderImage;
    deleteImageActions: UseAsyncActions<
        void,
        [order_id: string | number, image_id: string | number]
    >;
    showDelete?: boolean;
}

export const OrderImagePreview = ({
    orderId,
    image,
    deleteImageActions,
    showDelete = true,
}: OrderImagePreviewProps) => {
    const src = `${BUNNY_PULL_ZONE}/${image.path}`;

    const [openDelete, setOpenDelete] = React.useState<boolean>(false);

    function handleOpenDelete(event: React.MouseEvent) {
        event.stopPropagation();
        setOpenDelete(true);
    }

    function handleCloseDelete() {
        setOpenDelete(false);
    }

    function getBasename(path: string) {
        return path.split(/[\\/]/).pop();
    }

    return (
        <>
            <Tooltip title={getBasename(image.path)} arrow>
                <ListItem
                    key={`preview-${image.id}`}
                    sx={{
                        p: 0,
                        m: 0.5,
                        width: 80,
                        height: 80,
                        overflow: "hidden",
                        position: "relative",
                        borderRadius: 1.25,
                        display: "inline-flex",
                        border: "solid 1px #aaaaaa",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        window.open(src, "_blank");
                    }}
                >
                    <img
                        alt={`image-${image.id}`}
                        src={src}
                        height={"100%"}
                        width={"100%"}
                        style={{
                            objectFit: "cover",
                        }}
                    />

                    {showDelete && (
                        <IconButton
                            onClick={handleOpenDelete}
                            sx={{
                                p: 0,
                                top: 4,
                                right: 4,
                                position: "absolute",
                                color: "white",
                                bgcolor: alpha("#141414", 0.4),
                                "&:hover": {
                                    bgcolor: alpha("#141414", 0.7),
                                },
                            }}
                        >
                            <Close sx={{ fontSize: "20px" }} />
                        </IconButton>
                    )}
                </ListItem>
            </Tooltip>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle id="responsive-dialog-title">
                    Delete Image
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this image? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDelete}>
                        Cancel
                    </Button>
                    <FormButton
                        type={"button"}
                        onClick={() => {
                            deleteImageActions.execute(orderId, image.id);
                            handleCloseDelete();
                        }}
                        color={"error"}
                        variant={"contained"}
                    >
                        Delete Image
                    </FormButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
