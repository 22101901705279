import { UploadFile, AttachFile, Error } from "@mui/icons-material";
import { Box, Stack, styled, Typography } from "@mui/material";
import { UseAsyncActions } from "@react-hookz/web";
import { FormButton } from "@rhf-kit/mui";
import React from "react";

interface OrderImageDropZoneProps {
    orderId: string | number;
    loading: boolean;
    addImageActions: UseAsyncActions<
        void,
        [order_id: string | number, image: File]
    >;
}

export const OrderImageDropZone = ({
    orderId,
    loading,
    addImageActions,
}: OrderImageDropZoneProps) => {
    const [hover, setHover] = React.useState<boolean>(false);
    const [hoverError, setHoverError] = React.useState<boolean>(false);

    function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        setHover(true);

        // Check if any items are not images
        if (event.dataTransfer.items.length > 0) {
            const items = event.dataTransfer.items;
            for (let i = 0; i < items.length; i++) {
                const type = items[i].type;
                if (!type.startsWith("image/")) {
                    setHoverError(true);
                }
            }
        }
    }

    function handleDragEnd(event: React.DragEvent<HTMLDivElement>) {
        event?.preventDefault();
        setHover(false);
        setHoverError(false);
    }

    async function handleDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        setHover(false);
        setHoverError(false);

        if (event.dataTransfer.files.length > 0) {
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Only upload images
                if (file.type.startsWith("image/")) {
                    await addImageActions.execute(orderId, file);
                }
            }
        }
    }

    async function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                const file = event.target.files[i];

                // Only upload images
                if (file.type.startsWith("image/")) {
                    await addImageActions.execute(orderId, file);
                }
            }
        }
    }

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });

    return (
        <Box
            onDragOver={handleDragStart}
            onDragLeave={handleDragEnd}
            onDrop={handleDrop}
        >
            <Stack
                alignItems="center"
                justifyContent="space-between"
                p={4}
                spacing={2}
                border={"1px dashed"}
                borderRadius={"6px"}
                borderColor="#aaaaaa"
                bgcolor={hoverError ? "#feebeb" : hover ? "lightgrey" : ""}
                width="100%"
            >
                {hoverError ? (
                    <Error fontSize="large" />
                ) : (
                    <UploadFile fontSize="large" />
                )}
                <Typography
                    variant="caption"
                    textAlign={"center"}
                    color={"#617889"}
                    gutterBottom
                >
                    {hoverError
                        ? "Invalid file type"
                        : "Drag and drop an image file to upload it to this order."}
                </Typography>
                <FormButton
                    component={"label"}
                    variant={"contained"}
                    size={"small"}
                    loading={loading}
                    loadingPosition={"end"}
                    startIcon={<AttachFile fontSize={"inherit"} />}
                    sx={{
                        backgroundColor: "#40ABBA",
                        ":hover": { backgroundColor: "#2a6574" },
                    }}
                >
                    Select file(s)
                    <VisuallyHiddenInput
                        type={"file"}
                        accept={"image/*"}
                        multiple={true}
                        onChange={handleUpload}
                    />
                </FormButton>
            </Stack>
        </Box>
    );
};
