import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Card, IconButton, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";

interface CustomUrlCardProps {
    url: string;
}

export const CustomUrlCard = ({ url }: CustomUrlCardProps) => {
    const handleCopy = async () => {
        await navigator.clipboard.writeText(url);
        enqueueSnackbar("URL copied to clipboard!", { variant: "success" });
    };

    return (
        <Card
            sx={{
                alignSelf: "center",
                p: 2,
                mt: 2,
            }}
        >
            <Typography sx={{ fontWeight: 600 }}>
                Custom Delivery URL
            </Typography>
            <Typography
                sx={{ color: "#6B7280", fontSize: ".75em", pt: 0.5 }}
                onClick={handleCopy}
            >
                Custom Store URL:{" "}
                <span style={{ color: "#2563EB", cursor: "pointer" }}>
                    {url}
                </span>
                <IconButton onClick={handleCopy} disableRipple>
                    <ContentCopyIcon sx={{ width: "15px", color: "black" }} />
                </IconButton>
            </Typography>
        </Card>
    );
};
