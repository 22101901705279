import {
    getCustomOrder,
    getPaymentIntent as getCustomOrderPaymentIntent,
    getOrder,
} from "@/api/orders.ts";
import { getPaymentIntent } from "@/api/store";
import { OrderNotFound, PaymentIntentNotFound } from "@/components/dashboard";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useParams } from "react-router-dom";
import { ConfirmOrder } from "./components/ConfirmOrder";

export const OrderConfirmation = () => {
    const { store_id, order_id } = useParams();

    const {
        data: storeOrder,
        isFetching: storeOrderFetching,
        error: storeOrderError,
    } = useQuery({
        queryKey: ["store-order-conformation", store_id, order_id],
        queryFn: () => {
            if (store_id && order_id) {
                return getOrder(order_id);
            } else throw Error("No store id or order id");
        },
        enabled: !!store_id,
    });

    const {
        data: customOrder,
        isFetching: customOrderFetching,
        error: customOrderError,
    } = useQuery({
        queryKey: ["custom-order-conformation", order_id],
        queryFn: () => {
            if (order_id) {
                return getCustomOrder(parseInt(order_id));
            } else throw Error("No store id or order id");
        },
        enabled: !store_id,
    });

    const {
        data: storeOrderPaymentIntent,
        isFetching: storeOrderPaymentIntentFetching,
        error: storeOrderPaymentIntentError,
    } = useQuery({
        queryKey: ["store-order-conformation-pi", storeOrder],
        queryFn: () => {
            if (
                storeOrder &&
                storeOrder.store_id &&
                storeOrder.payment_intent_id
            ) {
                return getPaymentIntent(
                    storeOrder.store_id,
                    storeOrder.payment_intent_id
                );
            } else throw Error("No store id or payment intent id");
        },
        enabled: !!storeOrder,
    });

    const {
        data: customOrderPaymentIntent,
        isFetching: customOrderPaymentIntentFetching,
        error: customOrderPaymentIntentError,
    } = useQuery({
        queryKey: ["custom-order-conformation-pi", customOrder],
        queryFn: () => {
            if (customOrder && customOrder.payment_intent_id) {
                return getCustomOrderPaymentIntent(
                    customOrder.payment_intent_id
                );
            } else throw Error("No store id or order id");
        },
        enabled: !!customOrder,
    });

    const isLoading =
        storeOrderFetching ||
        customOrderFetching ||
        storeOrderPaymentIntentFetching ||
        customOrderPaymentIntentFetching;

    if (isLoading) {
        return (
            <Backdrop
                open={isLoading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );
    }

    const hasError =
        storeOrderPaymentIntentError || customOrderPaymentIntentError;

    if (hasError) {
        return <PaymentIntentNotFound />;
    }

    if (storeOrderError || customOrderError) {
        return <OrderNotFound />;
    }

    if (store_id && !storeOrderPaymentIntent && !storeOrder) {
        return null;
    }

    if (!store_id && !customOrderPaymentIntent && !customOrder) {
        return null;
    }

    const paymentIntentResult =
        storeOrderPaymentIntent || customOrderPaymentIntent;

    if (!paymentIntentResult) return null;

    if (paymentIntentResult.status !== "succeeded") {
        const link = storeOrder
            ? `/stores/${storeOrder.store_id}/shipping-form/${storeOrder.id}/checkout`
            : customOrder
              ? `/orders/${customOrder.id}/checkout`
              : "";
        return <Navigate to={link} replace />;
    }

    return (
        <Box bgcolor="#F3F4F6" minHeight="100vh" overflow="auto">
            <ConfirmOrder />
        </Box>
    );
};

export default OrderConfirmation;
