import { getOrderPaymentInfo, updateCustomOrder } from "@/api/orders";
import { DBStoreOrder, updateStoreOrder } from "@/api/store";
import { useAuth } from "@/components/context/useAuth";
import { getPaymentBreakdown } from "@/utils";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import {
    Box,
    Button,
    Card,
    Divider,
    Input,
    Stack,
    Typography,
} from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { OrderStatusChip } from "../../status/OrderStatusChip";
import { OrderPaymentInfoSkeleton } from "../skeletons";

interface IOrderPaymentInfoCardProps {
    order: DBStoreOrder;
}

export const OrderPaymentInfoCard = ({ order }: IOrderPaymentInfoCardProps) => {
    const { user } = useAuth();

    const [getPaymentInfoState, getPaymentInfoActions] =
        useAsync(getOrderPaymentInfo);
    const [updateOrderStatusState, updateOrderStatusActions] =
        useAsync(updateStoreOrder);
    const [updateCustomOrderState, updateCustomOrderActions] =
        useAsync(updateCustomOrder);

    useMountEffect(() => {
        getPaymentInfoActions.execute(order.id);
    });

    const [editBonus, setEditBonus] = useState(false);
    const [bonus, setBonus] = useState(
        order.driver_bonus ? order.driver_bonus : "0.00"
    );

    const handleBonusChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        let value = e.target.value;

        // Remove non-numeric characters
        value = value.replace(/[^0-9.]/g, "");

        // Remove leading decimal point
        value = value.replace(/^\./, "");

        // Remove multiple decimal points
        value = value.replace(/\.+/g, ".");

        // Limit to two decimal places
        value = value.replace(/(\.\d{2})\d+/, "$1");

        // Update the state with the formatted value
        setBonus(value);
    };

    const handleSubmitBonus = () => {
        if (order.store_id) {
            updateOrderStatusActions.execute(order.store_id, order.id, {
                driver_bonus: bonus,
            });
        } else {
            updateCustomOrderActions.execute(order.id, {
                driver_bonus: bonus,
            });
        }
        setBonus(bonus);
        setEditBonus(false);
    };

    useEffect(() => {
        if (updateOrderStatusState.status === "success") {
            enqueueSnackbar("Bonus updated successfully", {
                variant: "success",
            });
        }
        if (updateOrderStatusState.status === "error") {
            enqueueSnackbar("Failed to update bonus, please try again", {
                variant: "error",
            });
        }
    }, [updateOrderStatusState.status]);

    useEffect(() => {
        if (updateCustomOrderState.status === "success") {
            enqueueSnackbar("Bonus updated successfully", {
                variant: "success",
            });
        }
        if (updateCustomOrderState.status === "error") {
            enqueueSnackbar("Failed to update bonus, please try again", {
                variant: "error",
            });
        }
    }, [updateCustomOrderState.status]);

    if (getPaymentInfoState.status === "loading") {
        return <OrderPaymentInfoSkeleton />;
    }

    if (
        getPaymentInfoState.status === "success" &&
        getPaymentInfoState.result
    ) {
        // format payment breakdown
        const paymentBreakdown = getPaymentBreakdown(
            getPaymentInfoState.result,
            order
        );

        return (
            <Stack spacing={3}>
                <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
                    <Box mb={2}>
                        <Stack
                            direction="row"
                            spacing={1}
                            mb={1}
                            alignItems={"center"}
                        >
                            <PaymentRoundedIcon />
                            <Typography
                                mb={1}
                                variant="body2"
                                fontWeight={600}
                                textAlign={"left"}
                            >
                                Payment Information
                            </Typography>
                            <Box flexGrow={1} />
                            <OrderStatusChip
                                orderStatus={
                                    order.paid_driver ? "paid" : "unpaid"
                                }
                                order_id={order.id}
                                allowStatusUpdate
                            />
                        </Stack>
                        <Divider sx={{ marginBottom: 1 }} />
                        <Box>
                            <Stack gap={0.5}>
                                {paymentBreakdown
                                    .filter((item) => item.value !== null)
                                    .map(({ label, desc, value }, index) => (
                                        <Stack
                                            key={index}
                                            direction="row"
                                            mb={1}
                                        >
                                            <Typography
                                                variant="body2"
                                                textAlign={"left"}
                                                textTransform={"capitalize"}
                                                flex={1}
                                            >
                                                {label}
                                            </Typography>
                                            {desc && (
                                                <Typography
                                                    variant="caption"
                                                    textAlign={"left"}
                                                    textTransform={"capitalize"}
                                                    mx={1}
                                                    flex={1}
                                                >
                                                    {desc}
                                                </Typography>
                                            )}
                                            <Typography
                                                variant="body2"
                                                textAlign={"right"}
                                                flex={1}
                                            >
                                                $
                                                {(Number(value) / 100).toFixed(
                                                    2
                                                )}
                                            </Typography>
                                        </Stack>
                                    ))}
                            </Stack>
                        </Box>
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent={"space-between"}
                            >
                                <Typography
                                    variant="body1"
                                    fontWeight={600}
                                    textAlign={"left"}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    variant="body1"
                                    fontWeight={600}
                                    textAlign={"left"}
                                >
                                    {`$${(
                                        getPaymentInfoState.result.amount / 100
                                    ).toFixed(2)}`}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Card>
                {/* Driver Bonus */}
                {user?.is_admin && (
                    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
                        <Box mb={2}>
                            <Stack
                                direction="row"
                                spacing={1}
                                mb={1}
                                alignItems={"center"}
                            >
                                <PaymentRoundedIcon />
                                <Typography
                                    mb={1}
                                    variant="body2"
                                    fontWeight={600}
                                    textAlign={"left"}
                                >
                                    Driver Bonus
                                </Typography>
                                <Box flexGrow={1} />
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#40ABBA",
                                        ":hover": {
                                            backgroundColor: "#2a6574",
                                        },
                                        py: 0.25,
                                    }}
                                    onClick={() => setEditBonus(!editBonus)}
                                >
                                    <Typography variant="button">
                                        {bonus ? "Edit" : "Add"}
                                    </Typography>
                                </Button>
                            </Stack>
                            <Divider sx={{ marginBottom: 1 }} />
                            <Box>
                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        variant="body1"
                                        fontWeight={600}
                                        textAlign={"left"}
                                    >
                                        Bonus
                                    </Typography>
                                    <Box>
                                        {editBonus ? (
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    fontWeight={600}
                                                >
                                                    $
                                                </Typography>
                                                <Input
                                                    sx={{
                                                        textAlign: "right",
                                                        width: "70px",
                                                    }}
                                                    value={bonus}
                                                    onChange={handleBonusChange}
                                                    inputProps={{
                                                        inputMode: "decimal",
                                                    }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        py: 0.25,
                                                    }}
                                                    onClick={handleSubmitBonus}
                                                >
                                                    <Typography variant="button">
                                                        Save
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                fontWeight={600}
                                                textAlign={"left"}
                                            >
                                                ${bonus}
                                            </Typography>
                                        )}
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Card>
                )}
            </Stack>
        );
    }
};
