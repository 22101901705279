import { login as APILogin } from "@/api/auth";
import { useAuth } from "@/components/context/useAuth";
import {
    FormBox,
    FormScreenContainer,
} from "@/components/dashboard/forms/FormContainers";
import { BackButton } from "@/components/ui/BackButton";
import { Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useAsync } from "@react-hookz/web";
import {
    FormButton as Button,
    FormContainer,
    FormEmailElement,
    FormPasswordElement,
} from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type LoginFormData = {
    email: string;
    password: string;
};

const Login = () => {
    const { session, authenticated } = useAuth();
    const navigate = useNavigate();
    const [loginState, loginActions] = useAsync(APILogin);
    const [status, setStatus] = useState<"not-executed" | "loading">(
        "not-executed"
    );

    if (authenticated) {
        navigate("/");
    }

    const onSubmit = (data: LoginFormData) => {
        loginActions.execute(data.email, data.password);
    };

    useEffect(() => {
        if (loginState.status === "success" && loginState.result) {
            if (status === "not-executed") {
                setStatus("loading");
                session.create(loginState.result.access_token);
            }
        }
        if (loginState.status === "error") {
            enqueueSnackbar("Invalid email or password", { variant: "error" });
        }
    }, [loginState, navigate, session, status]);

    const initialData: LoginFormData = {
        email: "",
        password: "",
    };

    return (
        <>
            <FormScreenContainer>
                <FormBox>
                    <Box
                        position="relative"
                        display="flex"
                        justifyContent="center"
                        mb={2}
                    >
                        <Box position="absolute" left={0}>
                            <BackButton onClick={() => navigate(-1)} />
                        </Box>

                        <Typography
                            variant="h5"
                            fontWeight={600}
                            sx={{ display: "inline" }}
                        >
                            Sign In To Truk-Em
                        </Typography>
                    </Box>

                    <FormContainer
                        defaultValues={initialData}
                        onSuccess={onSubmit}
                    >
                        <FormEmailElement
                            name={"email"}
                            label={"Email Address"}
                            required
                            fullWidth
                            renderIcon={false}
                            margin={"normal"}
                        />
                        <FormPasswordElement
                            name={"password"}
                            label={"Password"}
                            required
                            fullWidth
                            margin={"normal"}
                        />

                        <Button
                            loading={
                                loginState.status === "loading" ||
                                status === "loading"
                            }
                            loadingPosition={"end"}
                            sx={{
                                background: "#40ABBA",
                                ":hover": {
                                    background: "#3a9aa7",
                                },
                                fontWeight: "bold",
                            }}
                            variant="contained"
                            fullWidth
                        >
                            Sign In
                        </Button>
                    </FormContainer>
                    <Typography variant="body1">
                        <Link
                            sx={{
                                color: "#40ABBA",
                                ":hover": {
                                    color: "#3a9aa7",
                                },
                            }}
                            href="/forgot-password"
                        >
                            Forgot Password?
                        </Link>
                    </Typography>
                </FormBox>
                <Box color="GrayText">
                    <Typography variant="body1">
                        © 2024 Pollytech LLC - Terms of Use
                    </Typography>
                </Box>
            </FormScreenContainer>
        </>
    );
};

export default Login;
