import { IDBCustomOrder } from "@/api/orders";
import { DBStoreOrder, IStore } from "../api/store";
import { OrderFormData as CustomOrderFormData } from "../components/dashboard/orders/process/CustomOrderForm";
import { OrderFormData } from "../components/dashboard/orders/process/OrderForm";

export const getInitialFormData = (
    order?: DBStoreOrder,
    store?: IStore
): OrderFormData => {
    return {
        firstName: order ? order.first_name : "",
        lastName: order ? order.last_name : "",
        email: order ? order.email : "",
        phoneNumber: order ? order.phone_number : "",
        address1: order ? order.delivery_address.address1 : "",
        streetAddress2: order ? order.delivery_address.address2 : "",
        city: order ? order.delivery_address.city : "",
        state: order ? order.delivery_address.state : "",
        zipCode: order ? order.delivery_address.zip_code : "",
        itemSize: order ? order.item_size : "",
        deliveryWindow: order ? order.delivery_window : "7 Days",
        itemFloor: order ? order.floor_level : 1,
        requiresAssembly: order ? order.assembly_required : false,
        weightLimit: order ? order.max_weight : false,
        needsWrapped: order ? order.needs_wrapped : false,
        elevatorAvailable: order ? order.elevator_available : false,
        itemDescription: order ? order.item_description : "",
        estimatedValue: order ? order.estimated_value : "",
        itemQuantity: order ? order.item_quantity : 1,
        orderLocation: order
            ? order.order_location
            : store
              ? [
                    store.store_address.address1,
                    store.store_address.address2,
                    store.store_address.city,
                    store.store_address.state,
                    store.store_address.zip_code,
                ]
                    .filter(Boolean)
                    .join(", ")
              : "",
        dropoffInstructions: order ? order.dropoff_instructions : "",
        referral: order ? order.referral : "",
    };
};

export const getCustomInitialFormData = (
    order?: IDBCustomOrder
): CustomOrderFormData => {
    return {
        firstName: order ? order.first_name : "",
        lastName: order ? order.last_name : "",
        email: order ? order.email : "",
        phoneNumber: order ? order.phone_number : "",
        pickup_streetAddress1: order ? order.pickup_address.address1 : "",
        pickup_streetAddress2: order ? order.pickup_address.address2 : "",
        pickup_city: order ? order.pickup_address.city : "",
        addReferral: order?.referral ? order.referral : "",
        pickup_state: order ? order.pickup_address.state : "",
        pickup_zipCode: order ? order.pickup_address.zip_code : "",
        delivery_streetAddress1: order ? order.delivery_address.address1 : "",
        delivery_streetAddress2: order ? order.delivery_address.address2 : "",
        delivery_city: order ? order.delivery_address.city : "",
        delivery_state: order ? order.delivery_address.state : "",
        delivery_zipCode: order ? order.delivery_address.zip_code : "",
        itemSize: order ? order.item_size : "",
        deliveryWindow: order ? order.delivery_window : "7 Days",
        itemFloor: order ? order.floor_level : 1,
        requiresAssembly: order ? order.assembly_required : false,
        weightLimit: order ? order.max_weight : false,
        needsWrapped: order ? order.needs_wrapped : false,
        elevatorAvailable: order ? order.elevator_available : false,
        itemDescription: order ? order.item_description : "",
        estimatedValue: order ? order.estimated_value : "",
        itemQuantity: order ? order.item_quantity : 1,
        dropoffInstructions: order ? order.dropoff_instructions : "",
    };
};
