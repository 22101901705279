import NotificationIcon from "@mui/icons-material/Notifications";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { IStoreUser, sendReminderEmail } from "@/api/users";

interface IRemindUserProps {
    user: IStoreUser;
}

export const RemindUser: React.FC<IRemindUserProps> = ({ user }) => {
    const [open, setOpen] = React.useState(false);
    const [remindUserState, remindUserActions] = useAsync(sendReminderEmail);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onClick = () => {
        remindUserActions.execute(user.id);
    };

    // Handle success and error states
    useEffect(() => {
        if (remindUserState.status === "success") {
            enqueueSnackbar(
                `A reminder email has been sent to ${user.email}.`,
                {
                    variant: "success",
                }
            );
            handleClose();
        }
    }, [remindUserState.status, user.email]);

    return (
        <>
            <Tooltip
                title={"This user has already completed their registration."}
                disableHoverListener={!user.has_logged_in}
            >
                <span>
                    <IconButton
                        disabled={user.has_logged_in}
                        onClick={handleOpen}
                    >
                        <NotificationIcon
                            aria-disabled={user.has_logged_in}
                            key="delete"
                            sx={{
                                color: user.has_logged_in ? "gray" : "green",
                                width: "16px",
                                height: "16px",
                                ":hover": { cursor: "pointer" },
                            }}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Send Registration Reminder
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        When sending a reminder, a new temporary password will
                        be set and provided to the user via email.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onClick}
                        color={"error"}
                        variant={"contained"}
                    >
                        {remindUserState.status === "loading" ? (
                            <CircularProgress size={20} color={"inherit"} />
                        ) : (
                            `Send reminder to ${user.email}`
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
