import { Box, Button, Toolbar } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { IDriverOrder, acceptOrder } from "@/api/profile";
import { formatDate } from "@/utils";
import {
    DataTable,
    DriverModal,
    EnhancedTableToolBar,
} from "@/components/dashboard";
import { GridColDef } from "@mui/x-data-grid";
import { useAsync } from "@react-hookz/web";
import { getOrder, getUnacceptedOrders } from "@/api/orders.ts";
import { useFilterModel } from "@/hooks";

interface IAvailableOrderRow {
    id: number;
    created_at: Date;
    delivery_window: string;
    promised_by: string;
    item_description: string;
    order: IDriverOrder;
}

export const DriverAllOrders = () => {
    const [driverOrderRows, setDriverOrderRows] = useState<
        IAvailableOrderRow[]
    >([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<IDriverOrder | null>(
        null
    );
    const [showOrderLocation, setShowOrderLocation] = useState(false);

    const { filterModel, handleFilterModelChange } = useFilterModel();

    // set default pagination model
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [getUnacceptedOrdersState, getUnacceptedOrdersActions] =
        useAsync(getUnacceptedOrders);

    useEffect(() => {
        getUnacceptedOrdersActions.execute(
            paginationModel.page + 1,
            paginationModel.pageSize,
            filterModel
        );
    }, [
        getUnacceptedOrdersActions,
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
    ]);

    useEffect(() => {
        if (
            getUnacceptedOrdersState.status === "success" &&
            getUnacceptedOrdersState.result
        ) {
            // don't show shopify orders
            // const filteredOrders = getUnacceptedOrdersState.result.items.filter(
            //   (order) => order.store_id || order.pickup_address,
            // );
            setDriverOrderRows(
                getUnacceptedOrdersState.result.items.map((order) => {
                    const created_at_date = new Date(order.created_at);
                    const promised_by_date = new Date(
                        new Date(order.created_at).setDate(
                            created_at_date.getDate() + 7
                        )
                    ).toDateString();
                    return {
                        id: order.id,
                        created_at: new Date(order.created_at),
                        delivery_window: order.delivery_window,
                        promised_by: promised_by_date,
                        item_description: order.item_description,
                        order,
                    } as IAvailableOrderRow;
                })
            );
        }
    }, [getUnacceptedOrdersState]);

    const autosizeOptions = {
        columns: [
            "created_at",
            "delivery_window",
            "item_description",
            "actions",
        ],
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    const handleAcceptOrder = async (orderId: string) => {
        try {
            await acceptOrder(Number(orderId));
            enqueueSnackbar("Order accepted successfully!", {
                variant: "success",
            });
            setDriverOrderRows(
                driverOrderRows.filter((order) => order.id !== Number(orderId))
            );
        } catch (error) {
            enqueueSnackbar("Failed to accept order.", { variant: "error" });
        }
        handleClose();
    };

    const handleOpen = (order: IDriverOrder) => {
        setSelectedOrder(order);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const refreshSelectedOrder = async () => {
        if (selectedOrder) {
            const order = await getOrder(selectedOrder.id);
            setSelectedOrder(order);
        }

        getUnacceptedOrdersActions.execute(
            paginationModel.page + 1,
            paginationModel.pageSize,
            filterModel
        );
    };

    const columns: GridColDef<IAvailableOrderRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "Order",
                sortingOrder: ["desc", "asc"],
                valueFormatter: (value) => {
                    if (!value) return value;

                    return `#${value}`;
                },
                display: "flex",
            },
            {
                field: "created_at",
                headerName: "Date",
                display: "flex",
                valueFormatter: (value) => {
                    if (!value) return value;

                    return formatDate(value);
                },
            },
            {
                field: "delivery_window",
                headerName: "Delivery Window",
                display: "flex",
                type: "singleSelect",
                valueOptions: [
                    { value: "7 Days", label: "7 Days" },
                    { value: "5 Days", label: "5 Days" },
                    { value: "3 Days", label: "3 Days" },
                    { value: "2 Days", label: "2 Days" },
                ],
            },
            {
                field: "promised_by",
                headerName: "Promised By",
                display: "flex",
            },
            {
                field: "item_description",
                headerName: "Item Description",
                display: "flex",
            },
            {
                field: "actions",
                type: "actions",
                renderCell({ row }) {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={row.order.accepted}
                            onClick={() => handleOpen(row.order)}
                        >
                            View
                        </Button>
                    );
                },
            },
        ],

        []
    );

    const handleChange = (
        _event: React.MouseEvent<HTMLElement>,
        toggleValue: boolean
    ) => {
        setShowOrderLocation(toggleValue);
    };

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(getUnacceptedOrdersState.result?.total || 0);

    const rowCount = useMemo(() => {
        if (getUnacceptedOrdersState.result?.total !== undefined) {
            rowCountRef.current = getUnacceptedOrdersState.result?.total;
        }
        return rowCountRef.current;
    }, [getUnacceptedOrdersState.result?.total]);

    return (
        <Box
            sx={{
                backgroundColor: "#F4F7FE",
                minHeight: "100vh",
                width: "100%",
                overflowX: "auto",
            }}
        >
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />

            <DriverModal
                open={openModal}
                onClose={handleClose}
                order={selectedOrder}
                onAccept={handleAcceptOrder}
                showOrderLocation={showOrderLocation}
                onToggle={handleChange}
                refreshOrder={refreshSelectedOrder}
            />

            <Box sx={{ padding: "1em" }}>
                <EnhancedTableToolBar title={"Available Orders"} />
                <DataTable
                    loading={getUnacceptedOrdersState.status === "loading"}
                    rows={driverOrderRows}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "id", sort: "desc" }],
                        },
                    }}
                    autoSize
                    autosizeOptions={autosizeOptions}
                    paginationMode={"server"}
                    rowCount={rowCount}
                    pageSizeOptions={[10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    filterMode={"server"}
                    sortingMode={"server"}
                    onFilterModelChange={handleFilterModelChange}
                    onSortModelChange={handleFilterModelChange}
                />
            </Box>
        </Box>
    );
};
