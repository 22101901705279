import { Box, Link, Typography } from "@mui/material";

export const Footer = () => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            bgcolor="#EBF5F9"
            color="black"
            padding={2}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <img src="/logo.png" alt="Truk-Em Logo" width="100" />
                <Typography
                    align="center"
                    variant="body2"
                    fontWeight={900}
                    gutterBottom
                >
                    Powered By Truk-Em
                </Typography>
            </Box>
            <Box>
                <Typography variant="body1" fontWeight={700}>
                    Contact Info:
                </Typography>
                <Link
                    variant="body2"
                    marginRight={1}
                    href="mailto:support@trukem.com"
                >
                    support@trukem.com
                </Link>
                <Typography variant="body2">+1 (330) 423-5438</Typography>
            </Box>
        </Box>
    );
};
