import { FormDatePickerElement } from "@rhf-kit/mui/dist/FormDatePickerElement";
import { useFormContext } from "react-hook-form";

export const DriverCoverageDateSelector = () => {
    const { watch } = useFormContext();

    const driver = watch("driver");

    // Only show the selector if the driver checkbox is selected
    if (!driver) {
        return null;
    }

    return (
        <FormDatePickerElement
            name="driver_coverage_expires_at"
            label="Driver Coverage Expiration Date"
            formatDensity="spacious"
            inputProps={{
                size: "small",
                margin: "normal",
            }}
        />
    );
};
