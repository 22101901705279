import { getProfileStores } from "@/api/profile";
import { useAuth } from "@/components/context/useAuth";
import { FormBox, FormScreenContainer } from "@/components/dashboard";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { FormButton, FormContainer } from "@rhf-kit/mui";
import { Navigate, useNavigate } from "react-router-dom";

export const Home = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [getUserStoresState, getUserStoresActions] =
        useAsync(getProfileStores);

    useMountEffect(() => {
        if (user) getUserStoresActions.execute();
    });

    if (getUserStoresState.status === "loading")
        return (
            <Backdrop
                open={getUserStoresState.status === "loading"}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (getUserStoresState.error)
        return (
            <Typography variant="h6">
                There was an error loading stores.
            </Typography>
        );

    const redirectTo = () => {
        if (!user) {
            return "/";
        }

        if (user.is_admin) return "/dashboard/admin/orders";
        if (user.is_driver) return "/dashboard/driver/orders";
        if (
            getUserStoresState.result &&
            getUserStoresState.result.items.length > 0
        ) {
            return `/dashboard/stores/${getUserStoresState.result.items[0].id}/orders`;
        }
        return "/dashboard";
    };

    if (user) return <Navigate to={redirectTo()} />;

    return (
        <FormScreenContainer>
            <FormBox>
                <FormContainer>
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        textAlign={"center"}
                        mb={2}
                    >
                        Welcome to Truk-Em
                    </Typography>

                    <FormButton
                        size="large"
                        sx={{
                            p: 2.5,
                            my: 2,
                            background: "#40ABBA",
                            ":hover": {
                                background: "#3a9aa7",
                            },
                            fontWeight: "bold",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            navigate("orders/create");
                        }}
                    >
                        Continue As Guest
                    </FormButton>
                    <Typography component={"a"} href="/login" color="grey">
                        Sign In to Dashboard
                    </Typography>
                </FormContainer>
            </FormBox>
        </FormScreenContainer>
    );
};

export default Home;
